
import Http from './Http'

export default {
    async getQueuesStats() {
        return await Http.get(`/monitor/queue/stats`)
    },
    async getQueueJobs(version) {
        return await Http.get(`monitor/queue/jobs?version=${version}&all=Y`)
    },
    async getQueueSuccessJobs(version) {
        return await Http.get(`/monitor/queue/success?version=${version}&all=Y`)
    },
    async getQueueFailedJobs(version) {
        return await Http.get(`/monitor/queue/failed?version=${version}&all=Y`)
    },
    async deleteJobFromQueue(id, version) {
        return await Http.delete(`/monitor/queue/${id}?version=${version}`)
    },
}